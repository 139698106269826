import React from "react";
import Layout from "../components/layout";
import MuruganPhoto from "../assets/images/members/murugan.jpg";
import GirishPhoto from "../assets/images/members/girish.jpg";
import ShivkumarPhoto from "../assets/images/members/shivkumar.jpg";
import GireesanPhoto from "../assets/images/members/gireesan.jpg";
import TejaramPhoto from "../assets/images/members/tejaram.jpg";
import AnandPhoto from "../assets/images/members/anand.jpg";
import SowmyaPhoto from "../assets/images/members/sowmya_girish.jpg";
import SangunthalaDeviPhoto from "../assets/images/members/sagunthala_devi.jpg";
import SathishBabuPhoto from "../assets/images/members/sathish_babu.jpg";
import SaravananChinthamaniPhoto from "../assets/images/members/saravanan_chinthamani.jpg";
import NaveenKumarPhoto from "../assets/images/members/naveen-kumar.jpg";
import VelmuruganPhoto from "../assets/images/members/velmurugan.jpg";
import SivadassPhoto from "../assets/images/members/sivadass.jpg";
import ChristopherKishorePhoto from "../assets/images/members/christopher_kishore.jpg";
import ElangoPhoto from "../assets/images/members/elango.jpg";
import AnupamaPhoto from "../assets/images/members/anupama.jpg";
import ArunachalamPhoto from "../assets/images/members/arunachalam.jpg";
import AliceLindhaPhoto from "../assets/images/members/alice_lindha.jpg";
import ShobanaPhoto from "../assets/images/members/shobana_vaidyanathan.jpg";
import SadhasivamPhoto from "../assets/images/members/sadhasivam.jpg";
import KalaimaniPhoto from "../assets/images/members/kalaimani.jpg";
import ThamaraiSelvamPhoto from "../assets/images/members/thamarai-selvam.jpg";
import SirajuddinPhoto from "../assets/images/members/sirajuddin.jpg";
import PalanivelPhoto from "../assets/images/members/palanivel_kumar.jpg";
import ShankarKumarPhoto from "../assets/images/members/shankar_kumar.jpg";
import VigneshPhoto from "../assets/images/members/vignesh.jpg";
import PoornimaPhoto from "../assets/images/members/poornima.jpg";

import VasanthaKumarPhoto from "../assets/images/members/vasanth.jpg";
import DefaultPhoto from "../assets/images/members/default.jpg";
import PhoneIcon from "../assets/images/icons/phone.svg";
import WhatsAppIcon from "../assets/images/icons/whatsapp.svg";
import "../styles/pages/our-team.scss";

const membersList = [
  {
    name: "Murugan P",
    position: "President",
    image: MuruganPhoto,
    phone: "+919445954794",
    isHead: true,
  },
  {
    name: "Sirajuddin S",
    position: "Vice President",
    image: SirajuddinPhoto,
    phone: "+919600621976",
  },
  {
    name: "Tejaram B K",
    position: "Vice President",
    image: TejaramPhoto,
    phone: "+919841367911",
  },
  {
    name: "Girishkumar M",
    position: "Treasurer",
    image: GirishPhoto,
    phone: "+919176276511",
    isHead: true,
  },
  {
    name: "Shivkumar N",
    position: "General Secretary",
    image: ShivkumarPhoto,
    phone: "+919176633106",
    isHead: true,
  },
  {
    name: "Sowmya Girish",
    position: "Joint Secretary",
    image: SowmyaPhoto,
  },
  {
    name: "Sivadass N",
    position: "Joint Secretary",
    image: SivadassPhoto,
    phone: "+918056277987",
  },
  {
    name: "Saravanan Chinthamani",
    position: "Joint Secretary",
    image: SaravananChinthamaniPhoto,
    phone: "+919841617535",
  },
  {
    name: "Velmurugan",
    position: "Joint Secretary",
    image: VelmuruganPhoto,
    phone: "+919790946865",
  },
  {
    name: "Naveen Kumar",
    position: "Joint Secretary",
    image: NaveenKumarPhoto,
    phone: "+919840535006",
  },
  {
    name: "Alice Lindha",
    position: "Executive Member",
    image: AliceLindhaPhoto,
  },
  {
    name: "Poornima D",
    position: "Executive Member",
    image: PoornimaPhoto,
  },
  {
    name: "Vignesh S R",
    position: "Executive Member",
    image: VigneshPhoto,
    phone: "+919791613422",
  },
  {
    name: "Anand K",
    position: "Executive Member",
    image: AnandPhoto,
    phone: "+919003131481",
  },
  {
    name: "Elango D",
    position: "Executive Member",
    image: ElangoPhoto,
    phone: "+919840928832",
  },
  {
    name: "Sathis Babu G",
    position: "Executive Member",
    image: SathishBabuPhoto,
    phone: "+919994424880",
  },
  {
    name: "Kalaimani Rajamanickam",
    position: "Executive Member",
    image: KalaimaniPhoto,
    phone: "+919842719523",
  },
  {
    name: "Christopher Kishore",
    position: "Executive Member",
    image: ChristopherKishorePhoto,
    phone: "+919842719523",
  },
  {
    name: "Vasantha Kumar",
    position: "Executive Member",
    image: VasanthaKumarPhoto,
    phone: "+919884089910",
  },
  {
    name: "Anupama Vignesh",
    position: "Executive Member",
    image: AnupamaPhoto,
    phone: "+919894725459",
  },
  {
    name: "Arunnachalam",
    position: "Executive Member",
    image: ArunachalamPhoto,
    phone: "+919840703458",
  },
  {
    name: "Thamari Selvam",
    position: "Executive Member",
    image: ThamaraiSelvamPhoto,
    phone: "+919445319281",
  },
];

const Member = ({ member }) => {
  return (
    <li>
      <div
        className={member.isHead ? "member-list-item head" : "member-list-item"}
      >
        <img src={member.image} alt={member.name} />
        <h3>{member.name}</h3>
        <p>{member.position}</p>
        <div className="actions">
          <a
            href={member.phone ? `tel:${member.phone}` : undefined}
            className={!member.phone ? "disabled" : ""}
          >
            <img src={PhoneIcon} alt="Mobile Number" />
          </a>
          <a
            href={member.phone ? `https://wa.me/${member.phone}` : undefined}
            className={!member.phone ? "disabled" : ""}
          >
            <img src={WhatsAppIcon} alt="Whatsapp Chat" />
          </a>
        </div>
      </div>
    </li>
  );
};

export default function Home() {
  const presidents = membersList.filter((m) =>
    ["President", "Vice President"].includes(m.position)
  );
  const secretaries = membersList.filter((m) =>
    ["General Secretary", "Joint Secretary"].includes(m.position)
  );
  const treasuries = membersList.filter((m) =>
    ["Treasurer"].includes(m.position)
  );
  const executives = membersList.filter((m) =>
    ["Executive Member"].includes(m.position)
  );
  return (
    <Layout title="Our Team">
      <h1 className="page-title">Our Team</h1>
      <div className="member-list">
        <h2>Presidents</h2>
        <ul>
          {presidents.map((member) => {
            return <Member member={member} />;
          })}
        </ul>
      </div>
      <div className="member-list">
        <h2>Treasury</h2>
        <ul>
          {treasuries.map((member) => {
            return <Member member={member} />;
          })}
        </ul>
      </div>
      <div className="member-list">
        <h2>Secretaries</h2>
        <ul>
          {secretaries.map((member) => {
            return <Member member={member} />;
          })}
        </ul>
      </div>
      <div className="member-list">
        <h2>Executive Members</h2>
        <ul>
          {executives.map((member) => {
            return <Member member={member} />;
          })}
        </ul>
      </div>
    </Layout>
  );
}
